const setTypes = `query setTypes {
  setTypes {
      value
      label
      category
  }
}
`;

export default setTypes;
